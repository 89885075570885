import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const PREFIX = 'DialogContentActions';

const classes = {
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.button}`]: {
    marginBottom: '1rem',
  },
});

export const DialogContentActions = (props: {
  actions: Array<{
    title: string | JSX.Element;
    onClick: VoidFunction;
  }>;
}): JSX.Element => {
  return (
    <Root className={classes.container}>
      {props.actions.map((action, index) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={action.onClick}
          className={classes.button}
          key={index}
        >
          {action.title}
        </Button>
      ))}
    </Root>
  );
};
