import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  createProductCategory,
  getProductCategories,
  createProduct,
  getProducts,
  productUpdateAvailability,
  changeCategoriesUiOrder,
  deleteProduct,
  deleteCategory,
  CreateProductParamsI,
  categoryUpdateAvailability,
  UpdateProductParamsI,
  updateProduct,
  updateProductImage,
  UpdateProductCategoryParamsI,
  updateCategory,
} from './menuRequests';
import { floatToNormalized } from '@chillmenu/common/dist/lib/currencyFormatter';
import update from 'immutability-helper';
import { DashboardDrawerContext } from '../drawer/DashboardDrawer/DashboardDrawer';
import { getProcessedCategories } from '@chillmenu/common/dist/features/products/categories/util/getProcessedCategories';
import { EditMenu } from './EditMenu';
import { MenuTooltips } from './MenuTooltips';
import { MenuContext } from 'context/MenuContext';
import { appLocale } from 'intl';
import { ProductCategoriesDTO } from '@chillmenu/common/dist/features/products/categories/productCategories.dto';
import { ProductDTO } from '@chillmenu/common/features/products/product.dto';

export interface MenuContentContextI {
  categories: ProductCategoriesDTO[];
  products: ProductDTO[];
  createCategory: (params: {
    name: ProductCategoriesDTO['name'];
    description: ProductCategoriesDTO['description'];
    isAvailable: boolean;
  }) => Promise<void>;
  createProduct: (params: CreateProductParamsI) => Promise<void>;
  updateProduct: (
    id: string,
    params: NonNullable<UpdateProductParamsI['data']>,
  ) => Promise<void>;
  updateProductImage: (id: string, image: File) => Promise<void>;
  updateCategory: (
    id: string,
    params: NonNullable<UpdateProductCategoryParamsI>,
  ) => Promise<void>;
  deleteProduct: (params: { productId: string }) => Promise<void>;
  deleteCategory: (params: { categoryId: string }) => Promise<void>;
  changeCategoriesOrder: (categoriesUiOrder: string[]) => Promise<void>;
  onItemChangeAvailability: (
    params: onItemChangeAvailabilityParamsI,
  ) => Promise<void>;
}

// TODO move to other file

export const MenuContentContext = React.createContext<MenuContentContextI>({
  categories: [],
  products: [],
  createProduct: async () => undefined,
  updateProduct: async () => undefined,
  updateProductImage: async () => undefined,
  deleteProduct: async () => undefined,
  createCategory: async () => undefined,
  updateCategory: async () => undefined,
  deleteCategory: async () => undefined,
  changeCategoriesOrder: async () => undefined,
  onItemChangeAvailability: async () => undefined,
});

export type OnItemChangeAvailabilityDataI =
  | {
      type: 'product';
      productId: string;
    }
  | {
      type: 'product option value';
      productId: string;
      optionId: string;
      valueId: string;
    }
  | {
      type: 'category';
      categoryId: string;
    };
type onItemChangeAvailabilityParamsI = {
  isAvailable: boolean;
} & OnItemChangeAvailabilityDataI;

export const Menu = (): JSX.Element => {
  const locale = appLocale;

  const { setProductCategories: setDrawerProductCategories } = React.useContext(
    DashboardDrawerContext,
  );

  const { menu } = React.useContext(MenuContext);

  const [isLoading, setIsLoading] = React.useState(false);

  const [categories, setCategories] = React.useState<ProductCategoriesDTO[]>(
    [],
  );
  /** unsorted categories */
  const [rawCategories, setRawCategories] = React.useState<
    ProductCategoriesDTO[]
  >([]);
  const [products, setProducts] = React.useState<
    MenuContentContextI['products']
  >([]);

  /** sets the categories state with the correct order */
  const setCategoriesWithOrder = (
    categories: ProductCategoriesDTO[],
    categoriesUiOrder: string[],
  ) => {
    const sortedCategories = getProcessedCategories({
      categories,
      categoriesUiOrder,
    });

    setCategories(sortedCategories);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const [productCategoriesRes, productsRes] = await Promise.all([
          getProductCategories(),
          getProducts({
            includeOptions: true,
          }),
        ]);

        setRawCategories(productCategoriesRes.productCategories);

        setProducts(
          productsRes.products.map((product) => ({
            ...product,
            categoryId: product.category.id,
          })),
        );
      } catch (err) {
        alert('Loading failed');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [locale]);

  React.useEffect(() => {
    setCategoriesWithOrder(rawCategories, menu.categoriesUiOrder);
  }, [menu, rawCategories]);

  React.useEffect(() => {
    setDrawerProductCategories?.(categories);

    return () => setDrawerProductCategories([]);
  });

  const onCategoryCreate = async ({
    name,
    description,
    isAvailable,
  }: Parameters<MenuContentContextI['createCategory']>[0]) => {
    const { menu, productCategory } = await createProductCategory({
      name,
      description,
      locale,
      isAvailable,
    });

    setCategoriesWithOrder(
      [
        ...categories,
        {
          name,
          id: productCategory.id,
          discountPercentage: 0,
          isAvailable: true,
          description,
        },
      ],
      menu.categoriesUiOrder,
    );
  };

  const onCategoryUpdate = async (
    id: Parameters<MenuContentContextI['updateCategory']>[0],
    params: Parameters<MenuContentContextI['updateCategory']>[1],
  ) => {
    const {
      description,
      name,
      isAvailable,
      discountPercentage,
    } = await updateCategory(id, params);

    setCategories((existingCategories) => {
      const categoryIndex = existingCategories.findIndex((c) => c.id === id);

      return update(existingCategories, {
        [categoryIndex]: {
          $set: {
            id,
            description,
            name,
            isAvailable,
            discountPercentage,
          },
        },
      });
    });
  };

  const onProductCreate = async ({
    product,
  }: Parameters<MenuContentContextI['createProduct']>[0]) => {
    const {
      name,
      description,
      pricing,
      categoryId,
      orderIndex,
      isAvailable,
    } = product;
    // normalize pricing for each entry of pricing
    const normalizedPricing: typeof pricing = pricing.map((p) => ({
      name: p.name,
      amount: floatToNormalized(p.amount),
    }));

    const res = await createProduct({
      product: {
        description,
        name,
        categoryId,
        pricing: normalizedPricing,
        orderIndex,
        isAvailable,
      },
    });

    setProducts([
      ...products,
      {
        isAvailable: res.product.isAvailable,
        categoryId: res.product.categoryId,
        description: res.product.description,
        id: res.product.id,
        name: res.product.name,
        orderIndex: res.product.orderIndex,
        pricing: res.product.pricing,
        imageUrl: res.product.imageUrl,
      },
    ]);
  };

  const onProductUpdate = async (
    id: Parameters<MenuContentContextI['updateProduct']>[0],
    { product }: Parameters<MenuContentContextI['updateProduct']>[1],
  ) => {
    const {
      name,
      description,
      pricing,
      categoryId,
      orderIndex,
      isAvailable,
    } = product;
    // normalize pricing for each entry of pricing
    const normalizedPricing: typeof pricing = pricing.map((p) => ({
      name: p.name,
      amount: floatToNormalized(p.amount),
    }));

    const res = await updateProduct(id, {
      product: {
        description,
        name,
        categoryId,
        pricing: normalizedPricing,
        orderIndex,
        isAvailable,
      },
    });

    const { softDeleted } = res;
    if (!softDeleted) {
      throw new Error('No softDeleted in response');
    }

    setProducts((existingProducts) => {
      const softDeletedIndex = existingProducts.findIndex(
        ({ id }) => id === softDeleted.softDeletedProductId,
      );

      if (softDeletedIndex === -1) {
        return existingProducts;
      }

      return update(existingProducts, {
        $splice: [[softDeletedIndex, 1]],
        $push: [
          {
            categoryId: softDeleted.newProduct.categoryId,
            description: softDeleted.newProduct.description,
            id: softDeleted.newProduct.id,
            pricing: softDeleted.newProduct.pricing,
            name: softDeleted.newProduct.name,
            orderIndex: softDeleted.newProduct.orderIndex,
            isAvailable: softDeleted.newProduct.isAvailable,
            imageUrl: softDeleted.newProduct.imageUrl,
          },
        ],
      });
    });
  };

  const onProductImageUpdate = async (
    id: Parameters<MenuContentContextI['updateProductImage']>[0],
    image: Parameters<MenuContentContextI['updateProductImage']>[1],
  ) => {
    try {
      const res = await updateProductImage(id, image);

      const imageUrl = res.imageUrl;

      setProducts((existingProducts) => {
        const updatedProductIndex = existingProducts.findIndex(
          ({ id: prodId }) => prodId === id,
        );

        if (updatedProductIndex === -1) {
          return existingProducts;
        }

        return update(existingProducts, {
          [updatedProductIndex]: { imageUrl: { $set: imageUrl } },
        });
      });
    } catch (err) {
      alert('Image upload failed');
    }
  };

  const onItemChangeAvailability = async (
    params: onItemChangeAvailabilityParamsI,
  ) => {
    if (params.type === 'product') {
      const { productId, isAvailable } = params;

      await productUpdateAvailability({
        id: productId,
        isAvailable: isAvailable,
      });

      setProducts((existingProducts) => {
        const editedProductIndex = existingProducts.findIndex(
          ({ id }) => id === productId,
        );

        if (editedProductIndex === -1) {
          return existingProducts;
        }

        return update(existingProducts, {
          [editedProductIndex]: {
            isAvailable: { $set: isAvailable },
          },
        });
      });
    } else if (params.type === 'category') {
      const { categoryId, isAvailable } = params;

      await categoryUpdateAvailability({
        id: categoryId,
        isAvailable: isAvailable,
      });

      setCategories((existingCategories) => {
        const editedCategoryIndex = existingCategories.findIndex(
          ({ id }) => id === categoryId,
        );

        if (editedCategoryIndex === -1) {
          return existingCategories;
        }

        return update(existingCategories, {
          [editedCategoryIndex]: {
            isAvailable: { $set: isAvailable },
          },
        });
      });
    }
  };

  const onProductDelete = async ({
    productId,
  }: Parameters<MenuContentContextI['deleteProduct']>[0]) => {
    await deleteProduct({ id: productId });

    setProducts((existingProducts) => {
      const index = existingProducts.findIndex(
        (product) => product.id === productId,
      );

      if (index === -1) {
        return existingProducts;
      }
      return update(existingProducts, {
        $splice: [[index, 1]],
      });
    });
  };

  const onCategoryDelete = async ({
    categoryId,
  }: Parameters<MenuContentContextI['deleteCategory']>[0]) => {
    await deleteCategory({ id: categoryId });

    setCategories((existingCategories) => {
      const index = existingCategories.findIndex(
        (category) => category.id === categoryId,
      );

      if (index === -1) {
        return existingCategories;
      }
      return update(existingCategories, {
        $splice: [[index, 1]],
      });
    });
  };

  const onCategoriesChangeOrder = async (
    categoriesUiOrder: Parameters<
      MenuContentContextI['changeCategoriesOrder']
    >[0],
  ) => {
    await changeCategoriesUiOrder(categoriesUiOrder);
    setCategoriesWithOrder(categories, categoriesUiOrder);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <MenuContentContext.Provider
      value={{
        categories,
        products,
        onItemChangeAvailability,
        createProduct: onProductCreate,
        updateProduct: onProductUpdate,
        updateProductImage: onProductImageUpdate,
        deleteProduct: onProductDelete,
        createCategory: onCategoryCreate,
        updateCategory: onCategoryUpdate,
        deleteCategory: onCategoryDelete,
        changeCategoriesOrder: onCategoriesChangeOrder,
      }}
    >
      <EditMenu categories={categories} products={products} />
      <MenuTooltips />
    </MenuContentContext.Provider>
  );
};
