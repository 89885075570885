import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { MenuContentContext } from '../Menu';
import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { appIntl } from '../../../intl';

const PREFIX = 'DeleteCategoryDialog';

const classes = {
  formControl: `${PREFIX}-formControl`,
};

const StyledActionDialog = styled(ActionDialog)({
  [`& .${classes.formControl}`]: {
    marginTop: '1rem',
  },
});

export const DeleteCategoryDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}): JSX.Element => {
  const { products, categories, deleteCategory } = React.useContext(
    MenuContentContext,
  );
  const { open, setOpen } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const defaultFormValues = {
    categoryId: '',
  };
  const [formValues, setFormValues] = React.useState(defaultFormValues);

  const canDelete = React.useMemo(() => {
    return !products.find(
      ({ categoryId }) => categoryId === formValues.categoryId,
    );
  }, [formValues.categoryId, products]);

  const isFormValid = React.useMemo(() => {
    return !!formValues.categoryId;
  }, [formValues]);

  const closeDialog = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      setFormValues(defaultFormValues);

      await deleteCategory({ categoryId: formValues.categoryId });

      closeDialog();
    } catch (err) {
      alert(
        'Category delete failed. Please make sure that the category has no products.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onInputChange = (
    event: { target: { value: unknown } },
    key: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value as string,
    });
  };

  return (
    <StyledActionDialog
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      title="Διαγραφή κατηγορίας"
      dividers={true}
      submitAction={{
        name: 'ΔΙΑΓΡΑΦΗ',
        disabled: isSubmitting || !canDelete || !isFormValid,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        disabled: isSubmitting,
      }}
    >
      <form noValidate autoComplete="off">
        <FormControl
          className={classes.formControl}
          fullWidth
          variant="standard"
        >
          <InputLabel id="category-select-label">Κατηγορία</InputLabel>
          <Select
            id="category"
            variant="standard"
            value={formValues.categoryId || ''}
            onChange={(e) => {
              onInputChange(e, 'categoryId');
            }}
            style={{ marginTop: '1rem' }}
            labelId="category-select-label"
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {appIntl(category.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      {!canDelete && (
        <p>
          Αυτή η κατηγορία δε μπορεί να διαγραφεί επειδή έχει προϊόντα. Για να
          μπορέσετε να τη διαγράψετε, πρέπει πρώτα να διαγράψετε όλα τα προϊόντα
          της.
        </p>
      )}
    </StyledActionDialog>
  );
};
