import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { ProductsCreateUpdateBodyDTO } from '@chillmenu/common/dist/features/products/products.dashboard.dto';
import { normalizedToFloat } from '@chillmenu/common/dist/lib/currencyFormatter';
import { Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ShopContext } from 'context/ShopContext';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { fillEmptyLocales } from '../../../intl';
import { MenuContentContext, MenuContentContextI } from '../Menu';
import {
  BasicFormContent,
  BasicFormValuesI,
} from './BasicFormContent/BasicFormContent';
import {
  PricingFormContent,
  PricingFormValuesI,
} from './PricingFormContent/PricingFormContent';

const PREFIX = 'CreateEditProductDialog';

const classes = {
  group: `${PREFIX}-group`,
};

const StyledActionDialog = styled(ActionDialog)({
  [`& .${classes.group}`]: {
    padding: '1rem',
    marginBottom: '1rem',
  },
});

export type FormValuesI = BasicFormValuesI & PricingFormValuesI;

export const CreateEditProductDialog = (props: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  /** on mode "edit", it's the product which is being edited. Else, it will be used for initial values */
  referenceProduct?: MenuContentContextI['products'][0] | null;
  setReferenceProduct?: React.Dispatch<
    React.SetStateAction<MenuContentContextI['products'][0] | null>
  >;
  mode: 'create' | 'edit';
}): JSX.Element => {
  const { categories, createProduct, updateProduct } = React.useContext(
    MenuContentContext,
  );

  const {
    shop: { preferredLocales },
  } = React.useContext(ShopContext);

  const defaultValues = React.useRef<FormValuesI>(
    (null as unknown) as FormValuesI,
  );

  if (!defaultValues.current) {
    defaultValues.current = {
      productName: fillEmptyLocales(preferredLocales),
      productDescription: fillEmptyLocales(preferredLocales),
      categoryId: categories?.[categories?.length - 1]?.id || '',
      orderIndex: '1',
      pricing: [
        {
          name: fillEmptyLocales(preferredLocales),
          amount: '0',
        },
      ],
      sectionLanguage: 'el-GR',
    };
  }

  const form = useForm<FormValuesI>({
    defaultValues: defaultValues.current,
    mode: 'all',
  });

  const {
    isOpen,
    setOpen,
    referenceProduct,
    setReferenceProduct,
    mode,
  } = props;

  const currentReferenceProductId = useRef<string | null>(null);
  React.useEffect(() => {
    if (!referenceProduct) {
      currentReferenceProductId.current = null;
      return;
    }

    // Make sure that the effect does not run when the
    // referenceProduct has not changed
    if (referenceProduct.id === currentReferenceProductId.current) {
      return;
    }

    currentReferenceProductId.current = referenceProduct.id;

    // copy all values
    form.reset({
      categoryId: referenceProduct.categoryId,
      orderIndex: referenceProduct.orderIndex
        ? referenceProduct.orderIndex.toString()
        : '',
      productDescription: fillEmptyLocales(
        preferredLocales,
        referenceProduct.description,
      ),
      productName: fillEmptyLocales(preferredLocales, referenceProduct.name),
      pricing: referenceProduct.pricing.map(({ amount, name }) => ({
        name: fillEmptyLocales(preferredLocales, name),
        amount: normalizedToFloat(amount).toString(),
      })),
      sectionLanguage: 'el-GR',
    });
  }, [referenceProduct, mode, setOpen, form, preferredLocales]);

  const onSubmit = async () => {
    try {
      const {
        categoryId,
        orderIndex,
        pricing,
        productDescription,
        productName,
      } = form.getValues();

      const currentOrderIndex = +orderIndex;
      const currentCategoryId = categoryId;

      const productData: ProductsCreateUpdateBodyDTO = {
        product: {
          name: productName,
          description: productDescription,
          pricing: pricing.map((pricing) => ({
            name: pricing.name,
            amount: +pricing.amount,
          })),
          categoryId: currentCategoryId,
          orderIndex: currentOrderIndex,
          isAvailable: true,
        },
      };

      if (mode === 'create') {
        await createProduct(productData);
        defaultValues.current = {
          ...defaultValues.current,
          orderIndex: (currentOrderIndex + 1).toString(),
          categoryId: currentCategoryId,
        };
      } else if (mode === 'edit') {
        if (!referenceProduct?.id) {
          throw new Error('editing without an referenceProduct id');
        }

        await updateProduct(referenceProduct.id, productData);

        setOpen(false);
      }
    } catch (err) {
      alert('product creation failed');
    } finally {
      form.reset();
    }
  };

  const onCancel = () => {
    setReferenceProduct?.(null);
    setOpen(false);
  };

  const disabled = !form.formState.isValid || form.formState.isSubmitting;

  return (
    <StyledActionDialog
      open={isOpen}
      setOpen={setOpen}
      onCancel={onCancel}
      title={mode === 'edit' ? 'Επεξεργασία Προϊόντος' : 'Δημιουργία Προϊόντος'}
      onSubmit={form.handleSubmit(onSubmit)}
      submitAction={{
        name: mode === 'edit' ? 'ΕΠΕΞΕΡΓΑΣΙΑ' : 'ΔΗΜΙΟΥΡΓΙΑ',
        disabled,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        disabled: form.formState.isSubmitting,
      }}
    >
      <FormProvider {...form}>
        <form noValidate autoComplete="off">
          <Stack gap={1}>
            <Paper className={classes.group}>
              <BasicFormContent categories={categories} />
            </Paper>
            <Paper className={classes.group}>
              <PricingFormContent />
            </Paper>
          </Stack>
        </form>
      </FormProvider>
    </StyledActionDialog>
  );
};
