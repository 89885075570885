import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { CreateDialog } from './CreateDialog/CreateDialog';
import { EditDialog } from './EditDialog/EditDialog';

const PREFIX = 'MenuTooltips';

const classes = {
  fab: `${PREFIX}-fab`,
  addTooltip: `${PREFIX}-addTooltip`,
  editTooltip: `${PREFIX}-editTooltip`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.fab}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.addTooltip}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
    willChange: 'transform',
  },

  [`& .${classes.editTooltip}`]: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(1),
    willChange: 'transform',
  },
}));

export const MenuTooltips = (): JSX.Element => {
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  return (
    <Root>
      <Tooltip
        title="Προσθήκη"
        aria-label="add"
        onClick={() => setCreateDialogOpen(true)}
        placement="left"
      >
        <Fab color="primary" className={classes.addTooltip}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Tooltip
        title="Επεξεργασία"
        aria-label="edit"
        onClick={() => setEditDialogOpen(true)}
        placement="left"
      >
        <Fab color="primary" className={classes.editTooltip}>
          <EditIcon />
        </Fab>
      </Tooltip>

      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <EditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      />
    </Root>
  );
};
