import { LoginCreateOneDTO } from '@chillmenu/common/dist/features/login/login.dashboard.dto';
import { request, requestUrls } from '../../../utilities/requests';
import { Jwt } from '../../../utilities/Jwt';

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<void> => {
  try {
    const body: LoginCreateOneDTO['Request']['body'] = {
      email,
      password,
    };
    const res = await request<LoginCreateOneDTO['Response']>({
      url: requestUrls.login.base(),
      method: 'POST',
      body,
      misc: {
        credentials: 'include',
      },
    });

    if (res.accessToken) {
      Jwt.set(res.accessToken);
      window.location.reload();
    }
  } catch (err) {
    Jwt.remove();
    throw err;
  }
};

export const logout = (): void => {
  Jwt.remove();
  window.location.reload();
};
