import React from 'react';
import { styled } from '@mui/material/styles';
import { Login } from '../auth/components/Login';
import Paper from '@mui/material/Paper';

const PREFIX = 'Home';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '10px',
  },
}));

export const Home = (): JSX.Element => {
  return (
    <Root className={classes.container}>
      <Paper className={classes.paper}>
        <Login />
      </Paper>
    </Root>
  );
};
