import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { logout } from '../api/authenticationRequests';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const LogoutListItem = (): JSX.Element => {
  return (
    <ListItem button key="Logout" onClick={() => logout()}>
      <ListItemIcon>
        <ExitToAppIcon color="secondary" />
      </ListItemIcon>
      <ListItemText primary="Έξοδος" />
    </ListItem>
  );
};
