import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { AuthenticationRouter } from '../features/auth/AuthenticationRouter';
import { noUserSelection } from '@chillmenu/common/dist/components/styling/noUserSelection';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import 'dayjs/locale/el';
import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';

const useStyles = makeStyles()((theme) => ({
  AppContainer: {
    ...noUserSelection(theme),
  },
}));

dayjs.extend(calendar);
dayjs.locale('el');

export type AuthData = {
  loggedIn: boolean;
  userId: string | null;
  shopId: string | null;
};

export const AuthenticationContext = React.createContext<
  AuthData & {
    setAuthData: React.Dispatch<React.SetStateAction<AuthData>>;
  }
>({
  loggedIn: false,
  userId: null,
  shopId: null,
  setAuthData: () => null,
});

const App = (): JSX.Element => {
  const { classes } = useStyles();
  const [authData, setAuthData] = React.useState<AuthData>({
    loggedIn: false,
    userId: null,
    shopId: null,
  });

  return (
    <AuthenticationContext.Provider
      value={{
        ...authData,
        setAuthData,
      }}
    >
      <div className={classes.AppContainer}>
        <Router>
          <CssBaseline />
          <AuthenticationRouter />
        </Router>
      </div>
    </AuthenticationContext.Provider>
  );
};

export default App;
