import React, { createContext, ReactNode, useState } from 'react';
import { LocalState, LocalStateI } from 'utilities/LocalState';

export type LocalStateContextI = { localState: LocalStateI } & {
  setLocalState: React.Dispatch<React.SetStateAction<LocalStateI>>;
};

export const LocalStateContext = createContext<LocalStateContextI>({
  localState: {
    printOrders: {
      enabled: false,
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  setLocalState: () => null,
});

export const LocalStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [localState, setLocalState] = useState<LocalStateI>(
    LocalState.get() || {
      printOrders: {
        enabled: false,
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
  );

  return (
    <LocalStateContext.Provider value={{ setLocalState, localState }}>
      {children}
    </LocalStateContext.Provider>
  );
};
