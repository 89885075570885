import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { Card } from '@mui/material';
import { login } from '../api/authenticationRequests';
import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginFailedCard: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.common.white,
  },
}));

const validateEmail = (email: string) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(String(email).toLowerCase());
};

const limits = {
  maxPasswordLength: 8,
};

export const Login = (): JSX.Element => {
  const { classes } = useStyles();
  const [emailAddress, setEmailAddress] = React.useState('');
  const [isValidEmailAddress, setIsValidEmailAddress] = React.useState(true);

  const [password, setPassword] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);

  const onSubmit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    try {
      e?.preventDefault();
      setLoggingIn(true);
      await login({
        email: emailAddress,
        password,
      });
      setLoginFailed(false);
    } catch (err) {
      setLoginFailed(true);
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <div
      className={classes.container}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          onSubmit();
        }
      }}
    >
      <p>
        Καλώς ήρθατε για το ChillMenu για επιχειρήσεις. Παρακαλώ εισάγετε τα
        στοιχεία σας για να συνδεθείτε.
      </p>
      <form className={classes.form} autoComplete="on">
        {loginFailed && (
          <Card className={classes.loginFailedCard}>
            Αδυναμία σύνδεσης. Βεβαιωθείτε ότι τα στοιχεία είναι σωστά.
          </Card>
        )}
        <FormControl variant="standard">
          <InputLabel htmlFor="email-address-input">Διεύθυνση email</InputLabel>
          <Input
            id="email-address-input"
            name="email-address-input"
            autoFocus
            error={emailAddress.length > 0 && !isValidEmailAddress}
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
              setIsValidEmailAddress(validateEmail(e.target.value));
            }}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel htmlFor="password-input">Κωδικός</InputLabel>
          <Input
            id="password-input"
            name="password-input"
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={password.length > 0 && !isValidPassword}
            onChange={(e) => {
              setPassword(e.target.value);
              if (e.target.value.length < limits.maxPasswordLength) {
                setIsValidPassword(false);
              } else {
                setIsValidPassword(true);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword((p) => !p);
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValidEmailAddress || !isValidPassword || loggingIn}
          onClick={(e) => onSubmit(e)}
          style={{ marginTop: '1rem' }}
        >
          ΕΙΣΟΔΟΣ
        </Button>
      </form>
    </div>
  );
};
