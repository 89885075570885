import { FlagDropdown } from '@chillmenu/common/dist/components/FlagDropdown/FlagDropdown';
import { RHFController } from '@chillmenu/common/dist/components/RHFController/RHFController';
import { localeObjectToString } from '@chillmenu/common/dist/src/internationalization';
import { FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ShopContext } from '../../../../context/ShopContext';
import { FormValuesI } from '../CreateEditCategoryDialog';

export const Options = () => {
  const { control, getValues } = useFormContext<FormValuesI>();
  const language = useWatch({
    control,
    name: 'language',
  });
  const values = getValues();
  const {
    shop: { preferredLocales },
  } = React.useContext(ShopContext);

  return (
    <>
      <Grid container sx={{ justifyContent: 'flex-end' }}>
        <RHFController
          control={control}
          name="language"
          render={({ field }) => (
            <FlagDropdown
              locales={preferredLocales}
              setValue={field.onChange}
              value={field.value}
              size="small"
              localeTitles="greek"
            />
          )}
        />
      </Grid>
      <FormControl fullWidth variant="standard">
        <RHFController
          control={control}
          name={`categoryName.${language}`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              id="product-category-name"
              autoFocus
              label="Όνομα κατηγορίας"
              type="text"
              InputLabelProps={{ shrink: true }}
              placeholder={localeObjectToString(values.categoryName, 'el-GR')}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth variant="standard">
        <RHFController
          control={control}
          name={`categoryDescription.${language}`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              id="product-category-description"
              label="Περιγραφή κατηγορίας"
              type="text"
              InputLabelProps={{ shrink: true }}
              placeholder={localeObjectToString(
                values.categoryDescription,
                'el-GR',
              )}
            />
          )}
        />
      </FormControl>
    </>
  );
};
