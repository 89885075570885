import React from 'react';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@chillmenu/common/dist/components/Dialog/DialogTitle';
import { DialogContentActions } from '../DialogContentActions/DialogContentActions';
import { CategoriesChangeOrderDialog } from '../CategoriesChangeOrderDialog/CategoriesChangeOrderDialog';
import { DeleteCategoryDialog } from '../DeleteCategoryDialog/DeleteCategoryDialog';
import { ResponsiveDialog } from '@chillmenu/common/dist/components/Dialog/ResponsiveDialog';

export const EditDialog = (props: {
  open: boolean;
  onClose: VoidFunction;
}): JSX.Element => {
  const [
    categoriesChangeOrderOpen,
    setCategoriesChangeOrderReady,
  ] = React.useState(false);

  const [deleteCategoryOpen, setDeleteCategoryOpen] = React.useState(false);

  const editC2a = (callback: VoidFunction) => {
    callback();
    props.onClose();
  };

  return (
    <>
      <ResponsiveDialog open={props.open} onClose={props.onClose}>
        <DialogTitle onClose={props.onClose}>
          Επιλέξτε τι θα επεξεργαστείτε
        </DialogTitle>
        <DialogContent>
          <DialogContentActions
            actions={[
              {
                title: 'ΣΕΙΡΑ ΚΑΤΗΓΟΡΙΩΝ',
                onClick: () =>
                  editC2a(() => setCategoriesChangeOrderReady(true)),
              },
              {
                title: 'ΔΙΑΓΡΑΦΗ ΚΑΤΗΓΟΡΙΑΣ',
                onClick: () => editC2a(() => setDeleteCategoryOpen(true)),
              },
            ]}
          />
        </DialogContent>
      </ResponsiveDialog>
      <CategoriesChangeOrderDialog
        open={categoriesChangeOrderOpen}
        setOpen={setCategoriesChangeOrderReady}
      />
      <DeleteCategoryDialog
        open={deleteCategoryOpen}
        setOpen={setDeleteCategoryOpen}
      />
    </>
  );
};
