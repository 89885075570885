import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

import { Image } from '@chillmenu/common/dist/chillmenu-components/Images';

import { EditMenuProductListEntryData } from './EditMenuProductListEntry';
import { ImageUploadDialog } from './ImageUploadDialog';
import { appIntl } from '../../../intl';

export const ProductImageSection = (props: {
  product: EditMenuProductListEntryData;
}): JSX.Element => {
  const { product } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const openDialog = () => {
    setDialogIsOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <Box style={{ marginBottom: '1em' }}>
        {product.imageUrl && (
          <Image
            url={product.imageUrl}
            alt={appIntl(product.name)}
            variant="DashboardProductList"
            style={{
              width: '190px',
              height: '107px',
            }}
          />
        )}
      </Box>

      <Button color="primary" variant="contained" onClick={openDialog}>
        {product.imageUrl ? 'ΑΛΛΑΓΗ ΕΙΚΟΝΑΣ' : 'ΠΡΟΣΘΗΚΗ ΕΙΚΟΝΑΣ'}
      </Button>
      <ImageUploadDialog
        open={dialogIsOpen}
        setOpen={setDialogIsOpen}
        product={product}
      />
    </Box>
  );
};
