import {
  ProductDescription,
  ProductName,
} from '@chillmenu/common/dist/chillmenu-components/ProductCard';
import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';
import { ProductDTO } from '@chillmenu/common/dist/features/products/product.dto';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { alpha, Box, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import React from 'react';
import { appLocale } from '../../../intl';
import { AvailabilitySwitch } from '../AvailabilitySwitch/AvailabilitySwitch';
import { ProductImageSection } from './ProductImageSection';

const useStyles = makeStyles()((theme) => ({
  Container: {
    padding: '1rem',
    marginTop: '0.5rem',
    wordBreak: 'break-word',
    height: '100%',
  },
  NotAvailable: {
    backgroundColor: alpha(theme.palette.background.paper, 0.6),
  },
  InformationContainer: {
    textAlign: 'left',
    marginRight: '1em',
  },
}));

export type EditMenuProductListEntryData = Pick<
  ProductDTO,
  | 'id'
  | 'name'
  | 'description'
  | 'orderIndex'
  | 'pricing'
  | 'isAvailable'
  | 'imageUrl'
>;

export const EditMenuProductListEntry = (props: {
  product: EditMenuProductListEntryData;
  onEdit: VoidFunction;
  onDuplicate: VoidFunction;
  onDelete: VoidFunction;
}): JSX.Element => {
  const { product } = props;
  const { classes } = useStyles();

  const containerClasses = [classes.Container];
  if (!product.isAvailable) {
    containerClasses.push(classes.NotAvailable);
  }
  return (
    <Paper
      className={containerClasses.join(' ')}
      key={product.id}
      variant="outlined"
    >
      <Grid container spacing={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          <div className={classes.InformationContainer}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              <ProductName
                product={product}
                showProductIndexes={true}
                locale={appLocale}
              />
              <div>
                <IconButton
                  onClick={() => props.onEdit()}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  <Tooltip title="Επεξεργασία προϊόντος">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => props.onDuplicate()}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  <Tooltip title="Δημιουργία νέου προϊόντος με βάση αυτό">
                    <FilterNoneIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => props.onDelete()}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  <Tooltip title="Διαγραφή προϊόντος">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </div>
              <AvailabilitySwitch
                isAvailable={product.isAvailable}
                callbackData={{
                  type: 'product',
                  productId: product.id,
                }}
                label={product.isAvailable ? 'Διαθέσιμο' : 'Μη διαθέσιμο'}
              />
            </Box>
            <ProductDescription product={product} locale={appLocale} />
          </div>
          <ProductImageSection product={product} />
        </Box>
      </Grid>
    </Paper>
  );
};
