import { ProductCategoriesDTO } from '@chillmenu/common/features/products/categories/productCategories.dto';
import { ProductsCreateUpdateBodyDTO } from '@chillmenu/common/features/products/products.dashboard.dto';
import { FlagDropdown } from '@chillmenu/common/dist/components/FlagDropdown/FlagDropdown';
import { RHFController } from '@chillmenu/common/dist/components/RHFController/RHFController';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ShopContext } from '../../../../context/ShopContext';
import { appIntl } from '../../../../intl';
import { FormValuesI } from '../CreateEditProductDialog';
import { LocaleCode } from '@chillmenu/common/src/internationalization';
import { localeObjectToString } from '@chillmenu/common/dist/src/internationalization';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';

const PREFIX = 'BasicFormContent';

const classes = {
  formControl: `${PREFIX}-formControl`,
};

const Root = styled('div')({
  padding: '1rem',
  [`& .${classes.formControl}`]: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
});

export interface BasicFormValuesI {
  productName: ProductsCreateUpdateBodyDTO['product']['name'];
  productDescription: ProductsCreateUpdateBodyDTO['product']['description'];
  categoryId: string;
  orderIndex: string;
  sectionLanguage: LocaleCode;
}

export const BasicFormContent = (props: {
  categories: ProductCategoriesDTO[];
}): JSX.Element => {
  const { control, getValues } = useFormContext<FormValuesI>();

  const {
    shop: { preferredLocales },
  } = React.useContext(ShopContext);

  const language = useWatch({
    control,
    name: 'sectionLanguage',
  });

  const values = getValues();

  return (
    <Root>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <Conditional condition={preferredLocales.length > 1}>
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <RHFController
              control={control}
              name="sectionLanguage"
              render={({ field }) => (
                <FlagDropdown
                  locales={preferredLocales}
                  setValue={field.onChange}
                  value={field.value}
                  size="small"
                  localeTitles="greek"
                />
              )}
            />
          </Grid>
        </Conditional>

        <RHFController
          name={`productName.${language}`}
          control={control}
          rules={{
            validate: (v) => {
              if (language !== 'el-GR') {
                return true;
              }

              return v && v.length >= 1;
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              inputRef={field.ref}
              variant="standard"
              id="product-name"
              autoFocus
              InputLabelProps={{ shrink: true }}
              placeholder={localeObjectToString(values.productName, 'el-GR')}
              label="Όνομα προϊόντος"
              type="text"
              error={!!fieldState.error}
            />
          )}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <RHFController
          name={`productDescription.${language}`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              id="product-description"
              label="Περιγραφή προϊόντος"
              InputLabelProps={{ shrink: true }}
              placeholder={localeObjectToString(
                values.productDescription,
                'el-GR',
              )}
              type="text"
            />
          )}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <InputLabel id="product-category-select-label">Κατηγορία</InputLabel>
        <RHFController
          name="categoryId"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              id="product-category"
              variant="standard"
              style={{ marginTop: '1rem' }}
              labelId="product-category-select-label"
              error={!!fieldState.error}
            >
              {props.categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {appIntl(category.name)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <RHFController
          name="orderIndex"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="standard"
              id="product-order-index"
              label="Αριθμός στον κατάλογο"
              type="number"
              onFocus={(event) => {
                event.target.select();
              }}
              error={!!fieldState.error}
              helperText="Για να εμφανίζεται στον κατάλογο με την επιθυμητή σειρά."
              inputProps={{
                min: 0,
              }}
            />
          )}
        />
      </FormControl>
    </Root>
  );
};
