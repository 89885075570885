import { LocaleObject } from '@chillmenu/common/dist/src/internationalization';
import { localeObjectToString } from '@chillmenu/common/dist/src/internationalization';
import { LocaleCode } from '@chillmenu/common/src/internationalization';

export const appLocale = 'el-GR';

export const appIntl = (localeObject: LocaleObject) =>
  localeObjectToString(localeObject, appLocale);

/**
 * Ensures that all the passed locales are available in the resulting `LocaleObject`, filling in the missing values
 */
export const fillEmptyLocales = (
  locales: LocaleCode[],
  ofObject: LocaleObject = {},
): LocaleObject => {
  return locales.reduce(
    (result, locale) => {
      if (result.hasOwnProperty(locale)) {
        return result;
      }

      result[locale] = '';
      return result;
    },
    { ...ofObject },
  );
};
