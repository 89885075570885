import React from 'react';
import { Grid } from '@mui/material';
import update from 'immutability-helper';
import { SetupSaveButton } from './SetupSaveButton/SetupSaveButton';
import { TimeOrClosed } from '@chillmenu/common/features/shops/shops.dto';
import { AddressSetup } from './AddressSetup/AddressSetup';
import { ContactSetup } from './ContactSetup/ContactSetup';
import { InformationSetup } from './InformationSetup/InformationSetup';
import { ScheduleSetup } from './ScheduleSetup/ScheduleSetup';
import { LocalStateContext } from 'context/LocalStateContext';
import { MenuContext } from 'context/MenuContext';
import { ShopContext } from 'context/ShopContext';
import { LocalStateI } from '../../utilities/LocalState';
import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';
import { InternationalizationSetup } from './InternationalizationSetup/InternationalizationSetup';

export type ScheduleDayI = {
  isOpen: boolean;
  start: string;
  end: string;
};
const defaultDay = (): ScheduleDayI => ({
  isOpen: false,
  start: '08:00',
  end: '21:00',
});

type FormValuesI = {
  address: {
    street: string;
    number: string;
    city: string;
    postCode: string;
  };
  contact: {
    phoneNumber: string;
    emailAddress: string;
  };
  internationalization: {
    preferredLocales: LocaleCode[];
  };
  information: {
    menuViewInfo: string;
    menuViewAnnouncement: string;
  };
  schedule: {
    monday: ScheduleDayI;
    tuesday: ScheduleDayI;
    wednesday: ScheduleDayI;
    thursday: ScheduleDayI;
    friday: ScheduleDayI;
    saturday: ScheduleDayI;
    sunday: ScheduleDayI;
  };
  localState: LocalStateI;
};

type FormValuesSettersI = {
  setAddress: (data: FormValuesI['address']) => void;
  setInformation: (data: FormValuesI['information']) => void;
  setInternationalization: (data: FormValuesI['internationalization']) => void;
  setContact: (data: FormValuesI['contact']) => void;
  setSchedule: (data: FormValuesI['schedule']) => void;
  setLocalState: (data: FormValuesI['localState']) => void;
};

// TODO move to own file
export const SetupContext = React.createContext<
  FormValuesI & FormValuesSettersI
>({
  address: {
    city: '-',
    number: '-',
    street: '-',
    postCode: '-',
  },
  contact: {
    phoneNumber: '',
    emailAddress: '',
  },
  internationalization: {
    preferredLocales: ['el-GR'],
  },
  information: {
    menuViewAnnouncement: '',
    menuViewInfo: '',
  },
  schedule: {
    monday: defaultDay(),
    tuesday: defaultDay(),
    wednesday: defaultDay(),
    thursday: defaultDay(),
    friday: defaultDay(),
    saturday: defaultDay(),
    sunday: defaultDay(),
  },
  localState: {
    printOrders: {
      enabled: false,
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },
  setAddress: () => null,
  setContact: () => null,
  setSchedule: () => null,
  setInformation: () => null,
  setInternationalization: () => null,
  setLocalState: () => null,
});

const getFormDayByContext = (start: TimeOrClosed, end: TimeOrClosed) => ({
  isOpen: start !== 'closed',
  start: start === 'closed' ? '08:00' : start,
  end: end === 'closed' ? '21:00' : end,
});

const Setup = (): JSX.Element => {
  const { menu } = React.useContext(MenuContext);
  const { shop } = React.useContext(ShopContext);
  const { localState } = React.useContext(LocalStateContext);

  const [formValues, setFormValues] = React.useState<FormValuesI>({
    address: {
      city: '-',
      number: '-',
      street: '-',
      postCode: '-',
    },
    contact: {
      phoneNumber: '',
      emailAddress: '',
    },
    information: {
      menuViewAnnouncement: '',
      menuViewInfo: '',
    },
    internationalization: {
      preferredLocales: ['el-GR'],
    },
    schedule: {
      monday: defaultDay(),
      tuesday: defaultDay(),
      wednesday: defaultDay(),
      thursday: defaultDay(),
      friday: defaultDay(),
      saturday: defaultDay(),
      sunday: defaultDay(),
    },
    localState: {
      printOrders: {
        enabled: false,
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  });

  React.useEffect(() => {
    setFormValues((formValues) =>
      update(formValues, {
        address: {
          $set: {
            city: shop.address.city,
            street: shop.address.street,
            number: shop.address.number,
            postCode: shop.address.postCode,
          },
        },
        contact: {
          $set: {
            emailAddress: shop.contactEmail,
            phoneNumber: shop.phoneNumber,
          },
        },
        information: {
          $set: {
            menuViewAnnouncement: menu.menuViewAnnouncement,
            menuViewInfo: menu.menuViewInfo,
          },
        },
        internationalization: {
          $set: {
            preferredLocales: shop.preferredLocales,
          },
        },
        schedule: {
          $set: {
            monday: getFormDayByContext(
              shop.schedule.mondayStart,
              shop.schedule.mondayEnd,
            ),
            tuesday: getFormDayByContext(
              shop.schedule.tuesdayStart,
              shop.schedule.tuesdayEnd,
            ),
            wednesday: getFormDayByContext(
              shop.schedule.wednesdayStart,
              shop.schedule.wednesdayEnd,
            ),
            thursday: getFormDayByContext(
              shop.schedule.thursdayStart,
              shop.schedule.thursdayEnd,
            ),
            friday: getFormDayByContext(
              shop.schedule.fridayStart,
              shop.schedule.fridayEnd,
            ),
            saturday: getFormDayByContext(
              shop.schedule.saturdayStart,
              shop.schedule.saturdayEnd,
            ),
            sunday: getFormDayByContext(
              shop.schedule.sundayStart,
              shop.schedule.sundayEnd,
            ),
          },
        },
      }),
    );
  }, [shop, menu]);

  React.useEffect(() => {
    setFormValues((formValues) =>
      update(formValues, {
        localState: {
          $set: localState,
        },
      }),
    );
  }, [localState]);

  return (
    <SetupContext.Provider
      value={{
        address: formValues.address,
        contact: formValues.contact,
        schedule: formValues.schedule,
        information: formValues.information,
        localState: formValues.localState,
        internationalization: formValues.internationalization,
        setAddress: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                address: data,
              },
            }),
          ),
        setContact: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                contact: data,
              },
            }),
          ),
        setInformation: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                information: data,
              },
            }),
          ),
        setInternationalization: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                internationalization: data,
              },
            }),
          ),
        setSchedule: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                schedule: data,
              },
            }),
          ),
        setLocalState: (data) =>
          setFormValues((existingFormValues) =>
            update(existingFormValues, {
              $set: {
                ...existingFormValues,
                localState: data,
              },
            }),
          ),
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InformationSetup />
        </Grid>
        <Grid item xs={12}>
          <ContactSetup />
        </Grid>
        <Grid item xs={12}>
          <InternationalizationSetup />
        </Grid>
        <Grid item xs={12}>
          <AddressSetup />
        </Grid>
        <Grid item xs={12}>
          <ScheduleSetup />
        </Grid>
      </Grid>

      <SetupSaveButton />
    </SetupContext.Provider>
  );
};

export default Setup;
