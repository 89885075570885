import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  FormControl,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { SetupContext } from '../Setup';

const PREFIX = 'ScheduleSetup';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
  inputWithStartLabel: `${PREFIX}-inputWithStartLabel`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.inputWithStartLabel}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const ScheduleSetup = (): JSX.Element => {
  const { schedule: formValues, setSchedule: setFormValues } = React.useContext(
    SetupContext,
  );

  type DayNames = keyof typeof formValues;

  const dayNameToFriendly: Record<DayNames, string> = {
    monday: 'Δευτέρα',
    tuesday: 'Τρίτη',
    wednesday: 'Τετάρτη',
    thursday: 'Πέμπτη',
    friday: 'Παρασκευή',
    saturday: 'Σάββατο',
    sunday: 'Κυριακή',
  };

  const setOpen = (
    event: React.ChangeEvent<HTMLInputElement>,
    day: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [day]: {
        ...formValues[day],
        isOpen: event.target.checked,
      },
    });
  };

  const setDaySchedule = (
    event: { target: { value: unknown } },
    day: keyof typeof formValues,
    type: 'start' | 'end',
  ) => {
    setFormValues({
      ...formValues,
      [day]: {
        ...formValues[day],
        [type]: event.target.value as string,
      },
    });
  };

  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5">Ωράριο</Typography>
      {Object.entries(formValues).map(([_day, values]) => {
        const day = _day as DayNames;
        return (
          <FormControl
            key={day}
            className={classes.formControl}
            fullWidth
            variant="standard"
          >
            <Paper>
              <Typography variant="body1">{dayNameToFriendly[day]}</Typography>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.isOpen}
                        onChange={(event) => setOpen(event, day)}
                      />
                    }
                    label="Ανοιχτά"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <TextField
                        variant="standard"
                        type="text"
                        value={values.start}
                        className={classes.inputWithStartLabel}
                        disabled={!values.isOpen}
                        onChange={(e) => setDaySchedule(e, day, 'start')}
                      />
                    }
                    label="Από"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <TextField
                        variant="standard"
                        type="text"
                        value={values.end}
                        className={classes.inputWithStartLabel}
                        disabled={!values.isOpen}
                        onChange={(e) => setDaySchedule(e, day, 'end')}
                      />
                    }
                    label="Έως"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Paper>
          </FormControl>
        );
      })}
    </StyledPaper>
  );
};
