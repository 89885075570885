import * as dotenv from 'dotenv';
dotenv.config();

const getEnvVar = (key: string): string => {
  const envVar = process.env[key];
  if (!envVar) {
    throw new Error(`${key} env missing`);
  }

  return envVar;
};

export const env = {
  server: {
    baseUrl: getEnvVar('REACT_APP_SERVER_BASE_URL'),
  },
  storeFront: {
    baseUrl: getEnvVar('REACT_APP_STOREFRONT_URL'),
  },
};
