import { MenusDTO } from '@chillmenu/common/dist/features/menus/menus.dto';
import React, { createContext } from 'react';

export type MenuContextI = {
  menu: Pick<
    MenusDTO,
    | 'id'
    | 'categoriesUiOrder'
    | 'showProductIndexes'
    | 'name'
    | 'menuViewInfo'
    | 'menuViewAnnouncement'
  >;
  setMenu: React.Dispatch<React.SetStateAction<MenuContextI['menu']>>;
};

export const defaultMenu = (): MenuContextI['menu'] => ({
  id: '',
  name: '',
  categoriesUiOrder: [],
  showProductIndexes: false,
  menuViewAnnouncement: '',
  menuViewInfo: '',
});

export const MenuContext = createContext<MenuContextI>({
  menu: defaultMenu(),
  setMenu: () => null,
});

export const MenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [menu, setMenu] = React.useState<MenuContextI['menu']>(defaultMenu());

  return (
    <MenuContext.Provider value={{ setMenu, menu }}>
      {children}
    </MenuContext.Provider>
  );
};
