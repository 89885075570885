import React from 'react';
import { Home } from '../home/Home.page';
import { Dashboard } from './Dashboard';
import { AuthenticationContext } from '../../app/App';

export const RoutesSwitch = (): JSX.Element => {
  const { loggedIn } = React.useContext(AuthenticationContext);

  if (!loggedIn) {
    return <Home />;
  }

  return <Dashboard />;
};
