import { Scroller } from '@chillmenu/common/dist/chillmenu-components/Scroller';
import { MenuProducts } from '@chillmenu/common/dist/chillmenu-components/MenuProducts';
import { Box, Grid, IconButton } from '@mui/material';
import React from 'react';
import { MenuContentContextI } from './Menu';
import { EditMenuProductListEntry } from './EditMenuProductListEntry/EditMenuProductListEntry';
import { AvailabilitySwitch } from './AvailabilitySwitch/AvailabilitySwitch';
import { CreateEditCategoryDialog } from './CreateEditCategoryDialog/CreateEditCategoryDialog';
import { CreateEditProductDialog } from './CreateEditProductDialog/CreateEditProductDialog';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteProductDialog } from './DeleteProductDialog/DeleteProductDialog';
import { ProductCategoriesDTO } from '@chillmenu/common/dist/features/products/categories/productCategories.dto';
import { ProductDTO } from '@chillmenu/common/features/products/product.dto';
import { CategoryHeader } from '@chillmenu/common/dist/chillmenu-components/CategoryHeader';
import { useSortedProducts } from '@chillmenu/common/dist/features/products/useSortedProducts.hook';
import { appLocale } from '../../intl';

export const EditMenu = (props: {
  products: ProductDTO[];
  categories: ProductCategoriesDTO[];
}): JSX.Element => {
  const sortedProducts = useSortedProducts({
    products: props.products,
    sortByAvailability: false,
  });

  const [editedProduct, setEditedProduct] = React.useState<
    MenuContentContextI['products'][0] | null
  >(null);

  const [isEditProductDialogOpen, setIsEditProductDialogOpen] = React.useState(
    false,
  );

  const [duplicatedProduct, setDuplicatedProduct] = React.useState<
    MenuContentContextI['products'][0] | null
  >(null);

  const [
    isDuplicateProductDialogOpen,
    setIsDuplicateProductDialogOpen,
  ] = React.useState(false);

  const [productToDelete, setProductToDelete] = React.useState<
    MenuContentContextI['products'][0] | null
  >(null);

  const [
    isDeleteProductDialogOpen,
    setIsDeleteProductDialogOpen,
  ] = React.useState(false);

  const [editedCategory, setEditedCategory] = React.useState<
    MenuContentContextI['categories'][0] | null
  >(null);

  const [
    isEditCategoryDialogOpen,
    setIsEditCategoryDialogOpen,
  ] = React.useState(false);

  return (
    <>
      <MenuProducts
        categories={props.categories}
        products={sortedProducts}
        categoryComponent={({ category, categoryId, products }) => (
          <>
            <Scroller.Component type="category" categoryId={categoryId} />
            <CategoryHeader
              category={category}
              locale={appLocale}
              belowHeaderComponent={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => {
                      setEditedCategory(category);
                      setIsEditCategoryDialogOpen(true);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <AvailabilitySwitch
                    isAvailable={category.isAvailable}
                    callbackData={{
                      type: 'category',
                      categoryId: categoryId,
                    }}
                    label={category.isAvailable ? 'Διαθέσιμο' : 'Μη διαθέσιμο'}
                  />
                </Box>
              }
            />
            <Grid container spacing={1}>
              {products.map((product) => (
                <Grid item lg={6} xs={12} key={product.id}>
                  <EditMenuProductListEntry
                    key={product.id}
                    product={product}
                    onEdit={() => {
                      setEditedProduct(product);
                      setIsEditProductDialogOpen(true);
                    }}
                    onDuplicate={() => {
                      setDuplicatedProduct(product);
                      setIsDuplicateProductDialogOpen(true);
                    }}
                    onDelete={() => {
                      setProductToDelete(product);
                      setIsDeleteProductDialogOpen(true);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      />
      <CreateEditProductDialog
        isOpen={isEditProductDialogOpen}
        setOpen={setIsEditProductDialogOpen}
        referenceProduct={editedProduct}
        setReferenceProduct={setEditedProduct}
        mode="edit"
      />
      <CreateEditProductDialog
        isOpen={isDuplicateProductDialogOpen}
        setOpen={setIsDuplicateProductDialogOpen}
        referenceProduct={
          duplicatedProduct
            ? {
                ...duplicatedProduct,
                name: {},
                orderIndex: (duplicatedProduct.orderIndex ?? -1) + 1,
              }
            : undefined
        }
        setReferenceProduct={setDuplicatedProduct}
        mode="create"
      />
      <CreateEditCategoryDialog
        isOpen={isEditCategoryDialogOpen}
        setOpen={setIsEditCategoryDialogOpen}
        editedCategory={editedCategory}
        setEditedCategory={setEditedCategory}
        mode="edit"
      />
      <DeleteProductDialog
        open={isDeleteProductDialogOpen}
        setOpen={setIsDeleteProductDialogOpen}
        productToDelete={productToDelete}
        setProductToDelete={setProductToDelete}
      />
    </>
  );
};
