import React from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';

/**
 * This component generalizes the usage of RHF Controller giving the ability to use dynamic names.
 * This does not have any impact or extra rerenders on stable names.
 */
export const RHFController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>
>(
  props: ControllerProps<TFieldValues, TName>,
) => {
  return (
    <Controller
      {...props}
      // setting the key here is important, because in case of dynamic name change we want the controller to remount
      key={props.name}
    />
  );
};
