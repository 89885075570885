import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import {
  LocaleCode,
  LocaleObject,
} from '@chillmenu/common/dist/src/internationalization';
import { styled } from '@mui/material/styles';
import { fillEmptyLocales } from 'intl';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ShopContext } from '../../../context/ShopContext';
import { MenuContentContext, MenuContentContextI } from '../Menu';
import { Options } from './Options/Options';

const PREFIX = 'CreateEditCategoryDialog';

const classes = {
  formControl: `${PREFIX}-formControl`,
};

const StyledActionDialog = styled(ActionDialog)({
  [`& .${classes.formControl}`]: {
    marginTop: '1rem',
  },
});

export interface FormValuesI {
  categoryName: LocaleObject;
  categoryDescription: LocaleObject;
  language: LocaleCode;
}

export const CreateEditCategoryDialog = (props: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  mode: 'create' | 'edit';
  /** category which is being edited */
  editedCategory?: MenuContentContextI['categories'][0] | null;
  setEditedCategory?: React.Dispatch<
    React.SetStateAction<MenuContentContextI['categories'][0] | null>
  >;
}): JSX.Element => {
  const defaultValues = React.useRef<FormValuesI>(
    (null as unknown) as FormValuesI,
  );

  const {
    shop: { preferredLocales },
  } = React.useContext(ShopContext);

  if (!defaultValues.current) {
    defaultValues.current = {
      categoryName: fillEmptyLocales(preferredLocales),
      categoryDescription: fillEmptyLocales(preferredLocales),
      language: preferredLocales[0],
    };
  }

  const form = useForm<FormValuesI>({
    defaultValues: defaultValues.current,
  });

  const { createCategory, updateCategory } = React.useContext(
    MenuContentContext,
  );

  const { isOpen, setOpen, mode, editedCategory, setEditedCategory } = props;

  React.useEffect(() => {
    if (!editedCategory || mode !== 'edit') {
      return;
    }
    const newValues: FormValuesI = {
      categoryName: fillEmptyLocales(preferredLocales, editedCategory.name),
      categoryDescription: fillEmptyLocales(
        preferredLocales,
        editedCategory.description,
      ),
      language: preferredLocales[0],
    };

    form.reset(newValues);
  }, [editedCategory, mode, form, preferredLocales]);

  const closeDialog = () => {
    setOpen(false);
  };
  const onSubmit = async (data: FormValuesI) => {
    try {
      if (mode === 'create') {
        await createCategory({
          name: data.categoryName,
          description: data.categoryDescription,
          isAvailable: true,
        });
      } else if (mode === 'edit') {
        if (!editedCategory) {
          throw new Error('editing category without editedCategory param');
        }
        await updateCategory(editedCategory.id, {
          name: data.categoryName,
          description: data.categoryDescription,
          isAvailable: editedCategory.isAvailable,
        });

        setEditedCategory?.(null);
      }

      form.reset(defaultValues.current);
      closeDialog();
    } catch (err) {
      alert('category creation failed');
    }
  };

  return (
    <StyledActionDialog
      open={isOpen}
      setOpen={setOpen}
      onSubmit={form.handleSubmit(onSubmit)}
      title={
        mode === 'edit' ? 'Επεξεργασία Κατηγορίας' : 'Δημιουργία Κατηγορίας'
      }
      submitAction={{
        name: mode === 'edit' ? 'ΕΠΕΞΕΡΓΑΣΙΑ' : 'ΔΗΜΙΟΥΡΓΙΑ',
        disabled: form.formState.isSubmitting,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        disabled: form.formState.isSubmitting,
      }}
    >
      <FormProvider {...form}>
        <form noValidate autoComplete="off">
          <Options />
        </form>
      </FormProvider>
    </StyledActionDialog>
  );
};
