import { styled } from '@mui/material/styles';
import { FlagDropdown } from '@chillmenu/common/dist/components/FlagDropdown/FlagDropdown';
import {
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { currencySymbol } from 'utilities/stringConsts';
import {
  LocaleCode,
  LocaleObject,
  localeObjectToString,
} from '@chillmenu/common/dist/src/internationalization';
import { useFormContext, useFieldArray, useWatch, get } from 'react-hook-form';
import { FormValuesI } from '../CreateEditProductDialog';
import { ShopContext } from '../../../../context/ShopContext';
import { fillEmptyLocales } from '../../../../intl';
import { RHFController } from '@chillmenu/common/dist/components/RHFController/RHFController';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';

const PREFIX = 'PricingFormContent';

const classes = {
  formControl: `${PREFIX}-formControl`,
  pricingContainer: `${PREFIX}-pricingContainer`,
};

const Root = styled('div')({
  padding: '1rem',
  [`& .${classes.formControl}`]: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  [`& .${classes.pricingContainer}`]: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0.5rem',
  },
});

export type PricingFormValuesI = {
  pricing: Array<{
    name: LocaleObject;
    amount: string;
  }>;
  sectionLanguage: LocaleCode;
};

export const PricingFormContent = (): JSX.Element => {
  const { control, getValues } = useFormContext<FormValuesI>();
  const {
    fields: pricingFields,
    append: addPricingEntry,
    remove: removePricingEntry,
  } = useFieldArray({ control, name: 'pricing' });
  const values = getValues();

  const language = useWatch({
    control,
    name: 'sectionLanguage',
  });

  const {
    shop: { preferredLocales },
  } = React.useContext(ShopContext);

  return (
    <Root>
      <Conditional condition={preferredLocales.length > 1}>
        <Grid container sx={{ justifyContent: 'flex-end' }}>
          <RHFController
            control={control}
            name="sectionLanguage"
            render={({ field }) => (
              <FlagDropdown
                locales={preferredLocales}
                setValue={field.onChange}
                value={field.value}
                size="small"
                localeTitles="greek"
              />
            )}
          />
        </Grid>
      </Conditional>
      <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
        Τιμές για QR κατάλογο
      </Typography>
      {pricingFields.map((pricing, index) => (
        <div key={pricing.id} className={classes.pricingContainer}>
          <FormControl className={classes.formControl} variant="standard">
            <RHFController
              name={`pricing.${index}.name.${language}`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  id={`product-price-name-${index}`}
                  label="Ονομασία (προαιρετική)"
                  InputLabelProps={{ shrink: true }}
                  placeholder={
                    localeObjectToString(
                      get<FormValuesI>(values, `pricing.${index}.name`),
                      'el-GR',
                    ) || `π.χ. "Μεγάλη"`
                  }
                  autoFocus
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  type="text"
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="standard">
            <RHFController
              name={`pricing.${index}.amount`}
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  variant="standard"
                  id={`product-price-amount-${index}`}
                  label="Τιμή"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  type="number"
                  error={!!fieldState.error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0,
                    step: 0.1,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="standard">
            <IconButton
              onClick={() => {
                removePricingEntry(index);
              }}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </FormControl>
        </div>
      ))}
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => {
            addPricingEntry({
              name: fillEmptyLocales(preferredLocales),
              amount: '0',
            });
          }}
        >
          ΝΕΑ ΤΙΜΗ
        </Button>
      </div>
    </Root>
  );
};
