import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { TokenValidateReadDTO } from '@chillmenu/common/dist/features/login/login.dashboard.dto';
import { Jwt } from '../../utilities/Jwt';
import { requestUrls, request } from '../../utilities/requests';
import { AuthenticationContext } from '../../app/App';
import { RoutesSwitch } from '../dashboard/RoutesSwitch';

export const AuthenticationRouter = (): JSX.Element => {
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = React.useState(false);
  const { setAuthData } = React.useContext(AuthenticationContext);

  React.useEffect(() => {
    (async () => {
      try {
        if (!Jwt.has()) {
          setAuthChecked(true);
          return;
        }

        const res = await request<TokenValidateReadDTO['Response']>({
          url: requestUrls.tokenValidate.base(),
          method: 'GET',
        });
        setAuthData({
          loggedIn: res.loggedIn,
          shopId: res.shopId || null,
          userId: res.userId || null,
        });

        setAuthChecked(true);

        if (!res.loggedIn) {
          navigate('/');
          return;
        }
      } catch (err) {
        navigate('/');
        return;
      }
    })();
  }, [navigate, setAuthData]);

  if (authChecked) {
    return <RoutesSwitch />;
  } else {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
