import React, { useContext, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';

import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { Image } from '@chillmenu/common/dist/chillmenu-components/Images';

import { EditMenuProductListEntryData } from './EditMenuProductListEntry';

import { MenuContentContext } from '../Menu';
import { appIntl } from '../../../intl';

export const ImageUploadDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  product: EditMenuProductListEntryData;
}): JSX.Element => {
  const { open, setOpen, product } = props;
  const { updateProductImage } = useContext(MenuContentContext);

  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleChangeUploadedImage = (
    ev: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFile = ev.target.files?.[0];
    if (newFile) {
      setUploadedImage(newFile);
    }
  };

  const closeModal = () => {
    setUploadedImage(null);
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (uploadedImage) {
      setIsUploading(true);
      await updateProductImage(product.id, uploadedImage);
      setIsUploading(false);
      closeModal();
    }
  };

  let imageComponent: JSX.Element | undefined;
  if (uploadedImage) {
    imageComponent = (
      <img
        src={URL.createObjectURL(uploadedImage)}
        alt=""
        style={{ maxWidth: '100%' }}
      />
    );
  } else if (product.imageUrl) {
    imageComponent = (
      <Image
        url={product.imageUrl}
        alt={appIntl(product.name)}
        variant="public"
      />
    );
  } else {
    imageComponent = <Typography>Δεν υπάρχει εικόνα</Typography>;
  }

  return (
    <ActionDialog
      open={open}
      title="Εικόνα Προϊόντος"
      setOpen={setOpen}
      onSubmit={handleSubmit}
      submitAction={{
        name: 'ΕΠΙΒΕΒΑΙΩΣΗ',
        disabled: !uploadedImage || isUploading,
        displaySpinner: isUploading,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        onClick: closeModal,
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box style={{ marginBottom: '2em', maxWidth: '70%' }}>
          {imageComponent}
        </Box>

        <Button color="primary" variant="contained" component="label">
          ΑΝΕΒΑΣΤΕ ΝΕΑ ΕΙΚΟΝΑ
          <input type="file" hidden onChange={handleChangeUploadedImage} />
        </Button>
      </Box>
    </ActionDialog>
  );
};
