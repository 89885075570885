import React from 'react';
import { MenuContentContext, MenuContentContextI } from '../Menu';
import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { Typography } from '@mui/material';

export const DeleteProductDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  productToDelete: MenuContentContextI['products'][0] | null;
  setProductToDelete: React.Dispatch<
    React.SetStateAction<MenuContentContextI['products'][0] | null>
  >;
}): JSX.Element => {
  const { productToDelete, setProductToDelete } = props;
  const { deleteProduct } = React.useContext(MenuContentContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const closeDialog = () => {
    props.setOpen(false);
  };

  const onCancel = () => {
    props.setOpen(false);
    setProductToDelete(null);
  };

  const onSubmit = async () => {
    if (!productToDelete) {
      alert(`Product to delete is not selected`);
      return;
    }
    try {
      setIsSubmitting(true);

      await deleteProduct({
        productId: productToDelete.id,
      });

      closeDialog();
      setProductToDelete(null);
    } catch (err) {
      alert('product delete failed');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ActionDialog
      open={props.open}
      setOpen={props.setOpen}
      title="Διαγραφή Προϊόντος"
      dividers={true}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitAction={{
        name: 'ΔΙΑΓΡΑΦΗ',
        disabled: isSubmitting,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        disabled: isSubmitting,
      }}
    >
      <Typography variant="h5">
        Αν διαγράψετε το προϊόν, θα αφαιρεθεί από τον κατάλογο, και δε θα
        αποτελεί πλέον επιλογή για παραγγελίες.
      </Typography>
    </ActionDialog>
  );
};
