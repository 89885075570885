import { Jwt } from './Jwt';
import { MemberRequestUrls } from '@chillmenu/common/dist/src/requests/requests';
import { env } from '../env';

export const requestUrls = new MemberRequestUrls(env.server.baseUrl);

export const request = async <T>(params: {
  url: string;
  method: 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT';
  body?: Record<string, unknown>;
  misc?: Partial<RequestInit>;
}): Promise<T> => {
  const fetchData: RequestInit = {
    method: params.method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + Jwt.get(),
    },
    ...params.misc,
  };
  if (params.body) {
    fetchData.body = JSON.stringify(params.body);
  }

  const fetchResult = await fetch(params.url, fetchData);

  const returnValue: T = await fetchResult.json();

  if (fetchResult.status < 200 || fetchResult.status >= 300) {
    throw new Error(JSON.stringify(returnValue));
  }

  return returnValue;
};

export const uploadFileRequest = async <T>(params: {
  url: string;
  inputFieldName: string;
  file: File;
  method: 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT';
  misc?: Partial<RequestInit>;
}): Promise<T> => {
  const formData = new FormData();
  formData.append(params.inputFieldName, params.file);

  const fetchResult = await fetch(params.url, {
    method: params.method,
    headers: {
      Authorization: 'bearer ' + Jwt.get(),
    },

    body: formData,
  });

  const returnValue: T = await fetchResult.json();

  if (fetchResult.status < 200 || fetchResult.status >= 300) {
    throw new Error(JSON.stringify(returnValue));
  }

  return returnValue;
};

export const searchParams = (
  params: Record<string, string | undefined | null>,
): string => {
  const paramsToUse = { ...params } as Record<string, string>;
  for (const key in paramsToUse) {
    if (paramsToUse[key] === null || paramsToUse[key] === undefined) {
      delete paramsToUse[key];
    }
  }

  return new URLSearchParams(paramsToUse).toString();
};
