import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography, FormControl, TextField } from '@mui/material';
import { SetupContext } from '../Setup';

const PREFIX = 'AddressSetup';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const AddressSetup = (): JSX.Element => {
  const { address: formValues, setAddress: setFormValues } = React.useContext(
    SetupContext,
  );

  const onInputChange = (
    event: { target: { value: unknown } },
    key: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value as string,
    });
  };

  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5">Διεύθυνση</Typography>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Πόλη/Περιοχή"
          type="text"
          value={formValues.city}
          onChange={(e) => onInputChange(e, 'city')}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Οδός"
          type="text"
          value={formValues.street}
          onChange={(e) => onInputChange(e, 'street')}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Αριθμός"
          type="text"
          value={formValues.number}
          onChange={(e) => onInputChange(e, 'number')}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Ταχυδρομικός Κώδικας"
          type="text"
          value={formValues.postCode}
          onChange={(e) => onInputChange(e, 'postCode')}
        />
      </FormControl>
    </StyledPaper>
  );
};
