export type LocalStateI = {
  printOrders: {
    enabled: boolean;
    paddingLeft: string;
    paddingRight: string;
  };
};

export class LocalState {
  private static identifier = 'app-state';
  public static set = (localState: LocalStateI): void =>
    localStorage.setItem(LocalState.identifier, JSON.stringify(localState));
  public static get = (): LocalStateI | null => {
    const item = localStorage.getItem(LocalState.identifier);
    return item ? JSON.parse(item) : null;
  };
  public static remove = (): void =>
    localStorage.removeItem(LocalState.identifier);
  public static has = (): boolean =>
    !!localStorage.getItem(LocalState.identifier);
}
