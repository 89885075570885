import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import { SetupContext } from '../Setup';
import {
  LocaleCode,
  localeCodes,
} from '@chillmenu/common/dist/src/internationalization';
import { LocalePaper } from './InternationalizatonSetup.styles';

const PREFIX = 'InternationalizationSetup';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const InternationalizationSetup = (): JSX.Element => {
  const {
    internationalization: formValues,
    setInternationalization: setFormValues,
  } = React.useContext(SetupContext);

  const orderedLocales = useMemo(() => {
    const { preferredLocales } = formValues;

    return [...localeCodes].sort((a, b) => {
      if (preferredLocales.includes(a) && !preferredLocales.includes(b)) {
        return -1;
      }

      if (!preferredLocales.includes(a) && preferredLocales.includes(b)) {
        return 1;
      }

      return preferredLocales.indexOf(a) > preferredLocales.indexOf(b) ? 1 : -1;
    });
  }, [formValues]);

  const setPreferredLocales = (preferredLocales: LocaleCode[]) => {
    setFormValues({
      ...formValues,
      preferredLocales,
    });
  };

  const moveLocale = (locale: LocaleCode, position: 'up' | 'down') => {
    const sourceIndex = formValues.preferredLocales.findIndex(
      (l) => locale === l,
    );

    if (
      sourceIndex === -1 ||
      (position === 'down' &&
        sourceIndex === formValues.preferredLocales.length - 1) ||
      (position === 'up' && sourceIndex === 0)
    ) {
      return;
    }

    const targetIndex = position === 'up' ? sourceIndex - 1 : sourceIndex + 1;

    const newLocales = [...formValues.preferredLocales];
    // swap source and target index
    [newLocales[sourceIndex], [newLocales[targetIndex]]] = [
      newLocales[targetIndex],
      [newLocales[sourceIndex]],
    ];

    setPreferredLocales(newLocales);
  };

  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5">Γλώσσες</Typography>
      {orderedLocales.map((locale, index) => (
        <LocalePaper
          locale={locale}
          isEnabled={formValues.preferredLocales.includes(locale)}
          canMoveDown={index !== formValues.preferredLocales.length - 1}
          canMoveUp={index !== 0}
          onMoveUp={() => moveLocale(locale, 'up')}
          onMoveDown={() => moveLocale(locale, 'down')}
          onToggle={() =>
            setPreferredLocales(
              formValues.preferredLocales.includes(locale)
                ? formValues.preferredLocales.filter((v) => v !== locale)
                : [...formValues.preferredLocales, locale],
            )
          }
          key={locale}
        />
      ))}
    </StyledPaper>
  );
};
