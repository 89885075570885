import React from 'react';
import { IconButton, Divider } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuContentContext } from '../Menu';
import { ActionDialog } from '@chillmenu/common/dist/components/Dialog/ActionDialog';
import { appIntl } from '../../../intl';

export const CategoriesChangeOrderDialog = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}): JSX.Element => {
  const { categories, changeCategoriesOrder } = React.useContext(
    MenuContentContext,
  );

  const [categoriesToUse, setCategoriesToUse] = React.useState<
    typeof categories
  >([]);

  React.useEffect(() => {
    setCategoriesToUse([...categories]);
  }, [categories]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const closeDialog = () => {
    props.setOpen(false);
  };

  const changeCategoryOrder = (categoryId: string, position: 'up' | 'down') => {
    const sourceIndex = categoriesToUse.findIndex(
      ({ id }) => id === categoryId,
    );
    if (
      sourceIndex === -1 ||
      (position === 'down' && sourceIndex === categoriesToUse.length - 1) ||
      (position === 'up' && sourceIndex === 0)
    ) {
      return;
    }

    const targetIndex = position === 'up' ? sourceIndex - 1 : sourceIndex + 1;

    const newCategories = [...categoriesToUse];
    // swap source and target index
    [newCategories[sourceIndex], [newCategories[targetIndex]]] = [
      newCategories[targetIndex],
      [newCategories[sourceIndex]],
    ];

    setCategoriesToUse(newCategories);
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      await changeCategoriesOrder(categoriesToUse.map(({ id }) => id));

      closeDialog();
    } catch (err) {
      alert('categories change order failed');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ActionDialog
      open={props.open}
      setOpen={props.setOpen}
      title="Επεξεργασία της σειράς των κατηγοριών"
      onSubmit={onSubmit}
      submitAction={{
        name: 'ΑΠΟΘΗΚΕΥΣΗ',
        disabled: isSubmitting,
      }}
      cancelAction={{
        name: 'ΑΚΥΡΩΣΗ',
        disabled: isSubmitting,
      }}
    >
      {categoriesToUse.map((category) => {
        return (
          <div key={category.id}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <IconButton
                aria-label="down"
                onClick={() => changeCategoryOrder(category.id, 'down')}
                size="large"
              >
                <KeyboardArrowDownIcon color="primary" />
              </IconButton>
              {appIntl(category.name)}
              <IconButton
                aria-label="up"
                onClick={() => changeCategoryOrder(category.id, 'up')}
                size="large"
              >
                <KeyboardArrowUpIcon color="primary" />
              </IconButton>
            </div>
            <Divider />
          </div>
        );
      })}
    </ActionDialog>
  );
};
