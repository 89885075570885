import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography, FormControl, TextField } from '@mui/material';
import { SetupContext } from '../Setup';

const PREFIX = 'ContactSetup';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const ContactSetup = (): JSX.Element => {
  const { contact: formValues, setContact: setFormValues } = React.useContext(
    SetupContext,
  );

  const onInputChange = (
    event: { target: { value: unknown } },
    key: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value as string,
    });
  };

  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5">Επικοινωνία</Typography>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Αριθμός τηλεφώνου"
          type="text"
          value={formValues.phoneNumber}
          onChange={(e) => onInputChange(e, 'phoneNumber')}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          label="Email"
          type="text"
          value={formValues.emailAddress}
          onChange={(e) => onInputChange(e, 'emailAddress')}
        />
      </FormControl>
    </StyledPaper>
  );
};
