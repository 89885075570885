import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { SetupContext, ScheduleDayI } from '../Setup';
import { Time } from '@chillmenu/common/dist/lib/time/time.type';
import { TimeOrClosed } from '@chillmenu/common/features/shops/shops.dto';
import { StickyButton } from '@chillmenu/common/dist/components/Buttons/StickyButton';
import { LocalStateContext } from 'context/LocalStateContext';
import { LocalState } from '../../../utilities/LocalState';
import { shopSetupUpdate } from '../../editMenu/menuRequests';

export const SetupSaveButton = (): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    address,
    contact,
    schedule,
    information,
    localState,
    internationalization,
  } = React.useContext(SetupContext);

  const { setLocalState } = React.useContext(LocalStateContext);

  const getTimeOrClosed = (
    scheduleDay: ScheduleDayI,
    type: 'start' | 'end',
  ): TimeOrClosed =>
    scheduleDay.isOpen ? (scheduleDay[type] as Time) : 'closed';

  return (
    <StickyButton
      color="primary"
      opaque
      disabled={isSubmitting}
      startIcon={<SaveIcon />}
      onClick={async () => {
        try {
          setIsSubmitting(true);

          LocalState.set(localState);
          setLocalState(localState);

          await shopSetupUpdate({
            address,
            contactEmail: contact.emailAddress,
            phoneNumber: contact.phoneNumber,
            menuViewInfo: information.menuViewInfo,
            menuViewAnnouncement: information.menuViewAnnouncement,
            preferredLocales: internationalization.preferredLocales,
            schedule: {
              mondayStart: getTimeOrClosed(schedule.monday, 'start'),
              mondayEnd: getTimeOrClosed(schedule.monday, 'end'),
              tuesdayStart: getTimeOrClosed(schedule.tuesday, 'start'),
              tuesdayEnd: getTimeOrClosed(schedule.tuesday, 'end'),
              wednesdayStart: getTimeOrClosed(schedule.wednesday, 'start'),
              wednesdayEnd: getTimeOrClosed(schedule.wednesday, 'end'),
              thursdayStart: getTimeOrClosed(schedule.thursday, 'start'),
              thursdayEnd: getTimeOrClosed(schedule.thursday, 'end'),
              fridayStart: getTimeOrClosed(schedule.friday, 'start'),
              fridayEnd: getTimeOrClosed(schedule.friday, 'end'),
              saturdayStart: getTimeOrClosed(schedule.saturday, 'start'),
              saturdayEnd: getTimeOrClosed(schedule.saturday, 'end'),
              sundayStart: getTimeOrClosed(schedule.sunday, 'start'),
              sundayEnd: getTimeOrClosed(schedule.sunday, 'end'),
            },
          });
        } catch (err) {
          alert('Setup save failed');
        } finally {
          setIsSubmitting(false);
          // NOTE temporary workaround as the "shop" state isn't updated
          window.location.reload();
        }
      }}
    >
      ΑΠΟΘΗΚΕΥΣΗ
    </StickyButton>
  );
};
