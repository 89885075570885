import React from 'react';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@chillmenu/common/dist/components/Dialog/DialogTitle';
import { DialogContentActions } from '../DialogContentActions/DialogContentActions';
import { CreateEditProductDialog } from '../CreateEditProductDialog/CreateEditProductDialog';
import { CreateEditCategoryDialog } from '../CreateEditCategoryDialog/CreateEditCategoryDialog';
import { ResponsiveDialog } from '@chillmenu/common/dist/components/Dialog/ResponsiveDialog';

export const CreateDialog = (props: {
  open: boolean;
  onClose: VoidFunction;
}): JSX.Element => {
  const [createCategoryOpen, setCreateCategoryOpen] = React.useState(false);
  const [createProductOpen, setCreateProductOpen] = React.useState(false);

  const addC2a = (callback: VoidFunction) => {
    callback();
    props.onClose();
  };

  return (
    <>
      <ResponsiveDialog open={props.open} onClose={props.onClose}>
        <DialogTitle onClose={props.onClose}>
          Επιλέξτε τι θα προσθέσετε
        </DialogTitle>
        <DialogContent>
          <DialogContentActions
            actions={[
              {
                title: 'ΚΑΤΗΓΟΡΙΑ',
                onClick: () => addC2a(() => setCreateCategoryOpen(true)),
              },
              {
                title: 'ΠΡΟΪΟΝ',
                onClick: () => addC2a(() => setCreateProductOpen(true)),
              },
            ]}
          />
        </DialogContent>
      </ResponsiveDialog>
      <CreateEditCategoryDialog
        isOpen={createCategoryOpen}
        setOpen={setCreateCategoryOpen}
        mode="create"
      />
      <CreateEditProductDialog
        isOpen={createProductOpen}
        setOpen={setCreateProductOpen}
        mode="create"
      />
    </>
  );
};
