import {
  ProductCategoriesCreateOneDTO,
  ProductCategoriesReadManyDTO,
  ProductCategoriesDeleteOneDTO,
  ProductCategoriesUpdateOneDTO,
} from '@chillmenu/common/dist/features/products/categories/productCategories.dashboard.dto';
import {
  MenusReadDTO,
  MenusUpdateDTO,
} from '@chillmenu/common/dist/features/menus/menus.dashboard.dto';
import {
  request,
  requestUrls,
  searchParams,
  uploadFileRequest,
} from '../../utilities/requests';
import {
  ProductsReadManyDTO,
  ProductsCreateOneDTO,
  ProductsUpdateOneDTO,
  ProductsDeleteOneDTO,
} from '@chillmenu/common/dist/features/products/products.dashboard.dto';
import {
  ShopsReadDTO,
  ShopsUpdateDTO,
} from '@chillmenu/common/dist/features/shops/shops.dashboard.dto';
import { ProductCategoriesDTO } from '@chillmenu/common/features/products/categories/productCategories.dto';
import { appLocale } from '../../intl';

export type CreateProductParamsI = ProductsCreateOneDTO['Request']['body'];
export type UpdateProductParamsI = ProductsUpdateOneDTO['Request']['body'];
export type UpdateProductCategoryParamsI = ProductCategoriesUpdateOneDTO['Request']['body'];

export const createProduct = async ({
  product,
}: CreateProductParamsI): Promise<ProductsCreateOneDTO['Response']> => {
  const body: ProductsCreateOneDTO['Request']['body'] = {
    product,
  };
  const res = await request<ProductsCreateOneDTO['Response']>({
    method: 'POST',
    url: requestUrls.products.base(),
    body,
  });

  return res;
};

export const createProductCategory = async ({
  name,
  description,
  isAvailable,
}: {
  name: ProductCategoriesDTO['name'];
  description: ProductCategoriesDTO['description'];
  locale: string;
  isAvailable: boolean;
}): Promise<ProductCategoriesCreateOneDTO['Response']> => {
  const body: ProductCategoriesCreateOneDTO['Request']['body'] = {
    productCategory: {
      description,
      name,
      isAvailable,
    },
  };
  const res = await request<ProductCategoriesCreateOneDTO['Response']>({
    method: 'POST',
    url: requestUrls.productCategories.base(),
    body,
  });

  return res;
};

export const getProductCategories = async (): Promise<
  ProductCategoriesReadManyDTO['Response']
> => {
  const res = await request<ProductCategoriesReadManyDTO['Response']>({
    method: 'GET',
    url: requestUrls.productCategories.base(),
  });

  return res;
};

export const getProducts = async ({
  includeOptions,
}: {
  includeOptions: boolean;
}): Promise<ProductsReadManyDTO['Response']> => {
  const queryParams: ProductsReadManyDTO['Request']['query'] = {};
  if (includeOptions) {
    queryParams.includeOptions = 'true';
  }
  const params = searchParams(queryParams);

  const res = await request<ProductsReadManyDTO['Response']>({
    method: 'GET',
    url: requestUrls.products.base() + `${params ? '?' + params : ''}`,
  });

  return res;
};

export const productUpdateAvailability = async ({
  id,
  isAvailable,
}: {
  id: string;
  isAvailable: boolean;
}): Promise<ProductsUpdateOneDTO['Response']> => {
  const body: ProductsUpdateOneDTO['Request']['body'] = { isAvailable };
  const res = await request<ProductsUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.products.one(id),
    body,
  });
  return res;
};

export const updateProduct = async (
  id: string,
  data: NonNullable<ProductsUpdateOneDTO['Request']['body']['data']>,
): Promise<ProductsUpdateOneDTO['Response']> => {
  const body: ProductsUpdateOneDTO['Request']['body'] = { data };
  const res = await request<ProductsUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.products.one(id),
    body,
  });
  return res;
};

export const updateProductImage = async (
  id: string,
  file: File,
): Promise<ProductsUpdateOneDTO['Response']> => {
  const res = await uploadFileRequest<ProductsUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.products.one(id),
    file,
    inputFieldName: 'image',
  });
  return res;
};

export const productOptionValueUpdateAvailability = async ({
  id,
  isAvailable,
}: {
  id: string;
  isAvailable: boolean;
}): Promise<ProductsUpdateOneDTO['Response']> => {
  const res = await request<ProductsUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.productOptionValues.one(id),
    body: {
      isAvailable,
    },
  });
  return res;
};

export const categoryUpdateAvailability = async ({
  id,
  isAvailable,
}: {
  id: string;
  isAvailable: boolean;
}): Promise<ProductCategoriesUpdateOneDTO['Response']> => {
  const body: ProductCategoriesUpdateOneDTO['Request']['body'] = {
    isAvailable,
  };
  const res = await request<ProductCategoriesUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.productCategories.one(id),
    body,
  });
  return res;
};

export const updateCategory = async (
  id: string,
  body: ProductCategoriesUpdateOneDTO['Request']['body'],
): Promise<ProductCategoriesUpdateOneDTO['Response']> => {
  const res = await request<ProductCategoriesUpdateOneDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.productCategories.one(id),
    body,
  });
  return res;
};

export const deleteProduct = async ({
  id,
}: {
  id: string;
}): Promise<ProductsDeleteOneDTO['Response']> => {
  const res = await request<ProductsDeleteOneDTO['Response']>({
    method: 'DELETE',
    url: requestUrls.products.one(id),
  });
  return res;
};

export const deleteCategory = async ({
  id,
}: {
  id: string;
}): Promise<ProductCategoriesDeleteOneDTO['Response']> => {
  const res = await request<ProductCategoriesDeleteOneDTO['Response']>({
    method: 'DELETE',
    url: requestUrls.productCategories.one(id),
  });
  return res;
};

export const changeCategoriesUiOrder = async (
  categoriesUiOrder: string[],
): Promise<MenusUpdateDTO['Response']> => {
  const body: MenusUpdateDTO['Request']['body'] = {
    categoriesUiOrder,
  };

  const res = await request<MenusUpdateDTO['Response']>({
    method: 'PATCH',
    url: requestUrls.menus.base(),
    body,
  });

  return res;
};

export const shopSetupUpdate = async ({
  address,
  contactEmail,
  phoneNumber,
  schedule,
  menuViewInfo,
  menuViewAnnouncement,
  preferredLocales,
}: NonNullable<
  Pick<
    MenusUpdateDTO['Request']['body'],
    'menuViewInfo' | 'menuViewAnnouncement'
  > &
    Pick<
      ShopsUpdateDTO['Request']['body'],
      | 'address'
      | 'contactEmail'
      | 'phoneNumber'
      | 'schedule'
      | 'preferredLocales'
    >
>): Promise<{
  menuRes: MenusUpdateDTO['Response'];
  shopRes: ShopsUpdateDTO['Response'];
}> => {
  const menuUpdateBody: MenusUpdateDTO['Request']['body'] = {
    menuViewInfo,
    menuViewAnnouncement,
  };

  const shopUpdateBody: ShopsUpdateDTO['Request']['body'] = {
    address,
    contactEmail,
    phoneNumber,
    schedule,
    preferredLocales,
  };

  const [menuRes, shopRes] = await Promise.all([
    request<MenusUpdateDTO['Response']>({
      method: 'PATCH',
      url: requestUrls.menus.base(),
      body: menuUpdateBody,
    }),
    request<ShopsUpdateDTO['Response']>({
      method: 'PATCH',
      url: requestUrls.shops.base(),
      body: shopUpdateBody,
    }),
  ]);

  return { menuRes, shopRes };
};

export const getMenu = async (): Promise<MenusReadDTO['Response']['menu']> => {
  const res = await request<MenusReadDTO['Response']>({
    method: 'GET',
    url: requestUrls.menus.base(),
  });
  return res.menu;
};

export const getShop = async (): Promise<ShopsReadDTO['Response']['shop']> => {
  const queryParams: ShopsReadDTO['Request']['query'] = {
    locale: appLocale,
  };

  const params = searchParams(queryParams);

  const res = await request<ShopsReadDTO['Response']>({
    method: 'GET',
    url: requestUrls.shops.base() + '?' + params,
  });

  return res.shop;
};
