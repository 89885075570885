import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { MenuContentContext, OnItemChangeAvailabilityDataI } from '../Menu';

export const AvailabilitySwitch = (props: {
  isAvailable: boolean;
  label: string | JSX.Element;
  callbackData: OnItemChangeAvailabilityDataI;
}): JSX.Element => {
  const { isAvailable } = props;

  const { onItemChangeAvailability } = React.useContext(MenuContentContext);

  const [
    itemAvailabilityIsSubmitting,
    setItemAvailabilityIsSubmitting,
  ] = React.useState(false);

  const label = <span style={{ userSelect: 'none' }}>{props.label}</span>;

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={itemAvailabilityIsSubmitting}
          checked={isAvailable}
          onChange={async (e) => {
            try {
              setItemAvailabilityIsSubmitting(true);
              await onItemChangeAvailability({
                ...props.callbackData,
                isAvailable: e.target.checked,
              });
            } catch (err) {
              alert('Cannot change product availability');
            } finally {
              setItemAvailabilityIsSubmitting(false);
            }
          }}
          color="primary"
          name="product-is-available"
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};
