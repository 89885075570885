import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from '../../chillmenu-components/Theme';

const useStyles = makeStyles()((theme) => ({
  Container: {
    position: 'sticky',
    bottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    willChange: 'transform',
  },
  Opaque: {
    padding: theme.spacing(1),
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  Button: {
    width: '90%',
    maxWidth: '600px',
    height: '42px',
    textTransform: 'none',
  },
}));

export const StickyButton = (props: {
  children: React.ReactNode;
  disabled?: boolean;
  color?: React.ComponentProps<typeof Button>['color'];
  startIcon?: React.ComponentProps<typeof Button>['startIcon'];
  opaque?: boolean;
  onClick?: VoidFunction;
}): JSX.Element => {
  const { disabled, color, opaque, startIcon, onClick } = props;
  const { classes } = useStyles();

  const classNames: string[] = [classes.Container];
  if (opaque) {
    classNames.push(classes.Opaque);
  }

  return (
    <div className={classNames.join(' ')}>
      <Button
        variant="contained"
        color={color || 'inherit'}
        disabled={disabled}
        className={classes.Button}
        onClick={onClick}
        startIcon={startIcon}
      >
        {props.children}
      </Button>
    </div>
  );
};
