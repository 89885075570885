import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography, FormControl, TextField } from '@mui/material';
import { SetupContext } from '../Setup';

const PREFIX = 'InformationSetup';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const InformationSetup = (): JSX.Element => {
  const {
    information: formValues,
    setInformation: setFormValues,
  } = React.useContext(SetupContext);

  const onInputChange = (
    event: { target: { value: unknown } },
    key: keyof typeof formValues,
  ) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value as string,
    });
  };

  return (
    <StyledPaper className={classes.container}>
      <Typography variant="h5">Πληροφορίες</Typography>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          multiline
          maxRows={4}
          label="Ανακοίνωση για προβολή μενού"
          type="text"
          value={formValues.menuViewAnnouncement}
          onChange={(e) => onInputChange(e, 'menuViewAnnouncement')}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl className={classes.formControl} fullWidth variant="standard">
        <TextField
          variant="standard"
          multiline
          maxRows={4}
          label="Πληροφορίες για προβολή μενού"
          type="text"
          value={formValues.menuViewInfo}
          onChange={(e) => onInputChange(e, 'menuViewInfo')}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    </StyledPaper>
  );
};
