import { makeStyles } from '@chillmenu/common/dist/chillmenu-components/Theme';
import { LocaleCode } from '@chillmenu/common/dist/src/internationalization';
import { localeTranslationsInGreek } from '@chillmenu/common/dist/src/internationalization';
import { Checkbox, FormControlLabel, IconButton, Paper } from '@mui/material';
import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useLocalePaperStyles = makeStyles()((theme) => ({
  Paper: {
    padding: theme.spacing(1),
  },
}));

export const LocalePaper: React.FC<{
  locale: LocaleCode;
  isEnabled: boolean;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onToggle: VoidFunction;
  onMoveUp: VoidFunction;
  onMoveDown: VoidFunction;
}> = ({
  locale,
  isEnabled,
  onToggle,
  canMoveDown,
  canMoveUp,
  onMoveDown,
  onMoveUp,
}) => {
  const { classes } = useLocalePaperStyles();

  const moveUpDisabled = !canMoveUp || !isEnabled;
  const moveDownDisabled = !canMoveDown || !isEnabled;
  const isGreek = locale === 'el-GR';

  return (
    <Paper className={classes.Paper} variant="outlined">
      <IconButton
        onClick={onMoveUp}
        disabled={moveUpDisabled}
        size="large"
        disableTouchRipple
      >
        <KeyboardArrowUpIcon color={moveUpDisabled ? 'disabled' : 'primary'} />
      </IconButton>
      <IconButton
        onClick={onMoveDown}
        disabled={moveDownDisabled}
        size="large"
        disableTouchRipple
      >
        <KeyboardArrowDownIcon
          color={moveDownDisabled ? 'disabled' : 'primary'}
        />
      </IconButton>
      <FormControlLabel
        control={
          <Checkbox checked={isEnabled} disabled={isGreek} onClick={onToggle} />
        }
        label={localeTranslationsInGreek[locale]}
      />
    </Paper>
  );
};
