import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { ChillMenuThemeProvider } from '@chillmenu/common/dist/chillmenu-components/Theme';

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
  <React.StrictMode>
    <ChillMenuThemeProvider>
      <App />
    </ChillMenuThemeProvider>
  </React.StrictMode>,
);

serviceWorker.unregister();
