import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveDrawer from '@chillmenu/common/dist/components/Drawer/ResponsiveDrawer';
import { Divider } from '@chillmenu/common/dist/components/Divider/Divider';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { LogoutListItem } from '../../auth/components/LogoutListItem';
import { Scroller } from '@chillmenu/common/dist/chillmenu-components/Scroller';
import { Conditional } from '@chillmenu/common/dist/components/Utils/Conditional';
import EditIcon from '@mui/icons-material/Edit';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { ShopContext } from 'context/ShopContext';
import { Anchor } from '@chillmenu/common/dist/components/Links/Anchor';
import { ProductCategoriesDTO } from '@chillmenu/common/features/products/categories/productCategories.dto';
import { appIntl } from '../../../intl';
import { dashboardUrls } from '../../../utilities/dashboardUrls';
import { storeFrontUrls } from '../../../utilities/storeFrontUrls';

// TODO move to own file
export const DashboardDrawerContext = React.createContext<{
  setProductCategories: (categories: ProductCategoriesDTO[]) => void;
}>({
  setProductCategories: () => null,
});

export const DashboardDrawer = (props: {
  children: React.ReactNode;
  title: string;
  label: string;
}): JSX.Element => {
  const navigate = useNavigate();
  let drawerMobileClose: VoidFunction;

  const navigateToUrl = (url: string) => {
    navigate(url);
    drawerMobileClose();
  };

  const { shop } = React.useContext(ShopContext);

  const [categories, setProductCategories] = React.useState<
    ProductCategoriesDTO[]
  >([]);

  const drawerLists = (
    <>
      <Divider />
      <List>
        <Anchor
          noTextDecoration
          newTab
          href={storeFrontUrls.menu(shop?.shortName ?? '')}
        >
          <ListItem button key="Menu View">
            <ListItemIcon>
              <RestaurantMenuIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Προβολή Μενού" />
          </ListItem>
        </Anchor>

        <ListItem
          button
          key="Menu Edit"
          onClick={() => {
            navigateToUrl(dashboardUrls.menu());
          }}
        >
          <ListItemIcon>
            <EditIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Επεξεργασία Μενού" />
        </ListItem>

        <ListItem
          button
          key="Setup"
          onClick={() => {
            navigateToUrl(dashboardUrls.setup());
          }}
        >
          <ListItemIcon>
            <SettingsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Ρυθμίσεις" />
        </ListItem>
      </List>

      <Conditional condition={!!categories.length}>
        <Divider heavy />
        <ListItem>
          <ListItemText primary={<b>Κατηγορίες</b>} />
        </ListItem>
        <Divider heavy />
        <List>
          {categories.map((category, index) => (
            <div
              key={category.id}
              onClick={() => {
                Scroller.scrollTo({
                  type: 'category',
                  categoryId: category.id,
                });
                drawerMobileClose();
              }}
            >
              <ListItem button>
                <ListItemText primary={appIntl(category.name)} />
              </ListItem>
              {index !== categories.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </Conditional>

      <Divider heavy />
      <LogoutListItem />
      <Divider />
    </>
  );

  return (
    <DashboardDrawerContext.Provider
      value={{
        setProductCategories,
      }}
    >
      <ResponsiveDrawer
        drawerLists={drawerLists}
        title={props.title}
        label={props.label}
        getCloseMobile={(gcm) => (drawerMobileClose = gcm)}
      >
        {props.children}
      </ResponsiveDrawer>
    </DashboardDrawerContext.Provider>
  );
};
