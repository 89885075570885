import { ShopsDTO } from '@chillmenu/common/dist/features/shops/shops.dto';
import { appLocale } from 'intl';
import React, { createContext, useEffect, useState } from 'react';
import { getShop } from '../features/editMenu/menuRequests';

export type ShopContextI = {
  shop: ShopsDTO;
  setShop: React.Dispatch<React.SetStateAction<ShopContextI['shop']>>;
};

const defaultShop = (): ShopContextI['shop'] => ({
  id: '',
  name: '',
  shortName: '',
  timezone: 'Europe/Athens',
  phoneNumber: '-',
  contactEmail: '-',
  identifier: '',
  preferredLocales: [appLocale],
  address: {
    city: '-',
    street: '-',
    number: '-',
    postCode: '-',
  },
  schedule: {
    mondayStart: 'closed',
    mondayEnd: 'closed',
    tuesdayStart: 'closed',
    tuesdayEnd: 'closed',
    wednesdayStart: 'closed',
    wednesdayEnd: 'closed',
    thursdayStart: 'closed',
    thursdayEnd: 'closed',
    fridayStart: 'closed',
    fridayEnd: 'closed',
    saturdayStart: 'closed',
    saturdayEnd: 'closed',
    sundayStart: 'closed',
    sundayEnd: 'closed',
  },
});

export const ShopContext = createContext<ShopContextI>({
  shop: defaultShop(),
  setShop: () => null,
});

export const ShopProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [shop, setShop] = useState<ShopContextI['shop']>(defaultShop());

  useEffect(() => {
    (async () => {
      const shopRes = await getShop();

      setShop(shopRes);
    })();
  }, []);

  return (
    <ShopContext.Provider value={{ shop, setShop }}>
      {children}
    </ShopContext.Provider>
  );
};
