import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { DashboardDrawer } from '../drawer/DashboardDrawer/DashboardDrawer';
import { CircularProgress } from '@mui/material';
import Setup from '../setup/Setup';
import { getMenu } from '../editMenu/menuRequests';
import { MenuContext, MenuProvider } from 'context/MenuContext';
import { ShopContext, ShopProvider } from 'context/ShopContext';
import { Menu } from '../editMenu/Menu';
import { dashboardUrls } from '../../utilities/dashboardUrls';
import { LocalStateProvider } from '../../context/LocalStateContext';

const _Dashboard = (): JSX.Element => {
  const { shop } = useContext(ShopContext);
  const { menu, setMenu } = useContext(MenuContext);

  useEffect(() => {
    if (!shop.id || menu.id) {
      return;
    }
    (async () => {
      const menuRes = await getMenu();

      setMenu({
        id: menuRes.id,
        name: menuRes.name,
        categoriesUiOrder: menuRes.categoriesUiOrder,
        showProductIndexes: menuRes.showProductIndexes,
        menuViewAnnouncement: menuRes.menuViewAnnouncement,
        menuViewInfo: menuRes.menuViewInfo,
      });
    })();
  }, [shop, menu, setMenu]);

  if (!shop.id) {
    return <CircularProgress />;
  }

  return (
    <DashboardDrawer title="Κέντρο Ελέγχου" label="dashboard navigation">
      <Routes>
        <Route path={dashboardUrls.menu()} element={<Menu />} />
        <Route path={dashboardUrls.setup()} element={<Setup />} />
        <Route path="/*" element={<Navigate to={dashboardUrls.menu()} />} />
      </Routes>
    </DashboardDrawer>
  );
};

export const Dashboard = () => (
  <LocalStateProvider>
    <ShopProvider>
      <MenuProvider>
        <_Dashboard />
      </MenuProvider>
    </ShopProvider>
  </LocalStateProvider>
);
